<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="rounded-xl border-0 shadow"
             body-class="pb-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="position-sticky header d-flex justify-content-between align-items-center bg-white pb-1 mb-3">
            <h1 class="font-weight-bolder mb-0">
                Advanced Search
            </h1>
            <button class="btn btn-lg btn-outline-dark border-0"
                    v-on:click="hideModal">
                <font-awesome-icon icon="xmark"></font-awesome-icon>
            </button>
        </div>
        <form v-on:submit.prevent.stop="search">
            <b-form-group label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="9"
                          label="📛 Keywords"
                          label-size="sm"
                          label-for="keywords">
                <b-form-input id="keywords"
                              class="bg-light rounded-pill"
                              size="sm">
                </b-form-input>
            </b-form-group>
            <b-form-group label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="9"
                          label="🏢 Location"
                          label-size="sm"
                          label-for="location">
                <b-form-input id="location"
                              class="bg-light rounded-pill"
                              size="sm">
                </b-form-input>
            </b-form-group>
            <b-form-group label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="9"
                          label="💰 Price"
                          label-size="sm"
                          label-for="price">
                <b-form-input id="price"
                              class="bg-light rounded-pill"
                              size="sm">
                </b-form-input>
            </b-form-group>
            <b-form-group label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="9"
                          label="👨‍💼 Organizer"
                          label-size="sm"
                          label-for="organizer">
                <v-select class="review-details-selector form-control form-control-sm bg-light rounded-pill shadow-sm py-0"
                          v-bind:options="organizers"
                          v-bind:reduce="({ _id }) => _id"
                          v-bind:get-option-label="({ name, countryCode, phone }) => `${ name } (+${ countryCode } ${ phone })`"
                          v-model="organizer">
                </v-select>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { BModal, BFormGroup, BFormInput, } from "bootstrap-vue";
export default {
    name: "ModalAdvancedActivitySearch",
    components: {
        vSelect,
        BModal, BFormGroup, BFormInput,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            organizer: "",
        };
    },
    computed: {
        organizers () {
            return this.$store.getters["users/itemsByRole"]("organizer").reverse();
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        search () {

        },
    },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.review-details-selector {
    .vs__dropdown-toggle {
        height: 100%;
        border: none;
    }
}
</style>
