import { render, staticRenderFns } from "./ActivitiesPlaceholder.vue?vue&type=template&id=f721c978&scoped=true&"
import script from "./ActivitiesPlaceholder.vue?vue&type=script&lang=js&"
export * from "./ActivitiesPlaceholder.vue?vue&type=script&lang=js&"
import style0 from "./ActivitiesPlaceholder.vue?vue&type=style&index=0&id=f721c978&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f721c978",
  null
  
)

export default component.exports