<template>
    <side-panel class="event-placeholder">
        <div class="position-sticky title px-3 pt-3 pb-2">
            <h3 class="font-weight-bolder text-right mb-0">
                ⛵Activities between <br>
                {{ startFormatted }}{{ startFormatted !== endFormatted ? `-${ endFormatted }` : "" }}
            </h3>
        </div>
        <div class="p-2">
            <div class="d-flex flex-nowrap align-items-center mb-3">
                <b-form-input class="flex-grow-1 rounded-pill bg-light mr-2"
                              size="sm"
                              placeholder="🔎 Search">
                </b-form-input>
                <button class="btn btn-sm btn-light rounded-circle shadow-sm border"
                        style="width: 35px; height: 33px; "
                        v-on:click="isModalSearchOpen = true">
                    <font-awesome-icon icon="bars"></font-awesome-icon>
                </button>
                <modal-advanced-activity-search v-model="isModalSearchOpen"></modal-advanced-activity-search>
            </div>
            <ul class="list-group shadow-sm rounded-xl mb-3">
                <li class="position-sticky list-group-item d-flex justify-content-between bg-light pl-3 pr-1 py-1">
                    <div class="font-weight-bolder">
                        {{ activities.length }} activities
                    </div>
                    <div>
                        <p class="text-nowrap mb-0">
                            <span class="badge rounded-pill bg-secondary text-white px-2 mr-1 mb-1">
                                {{ pendingCount }} pending
                            </span>
                            <span class="badge rounded-pill bg-danger text-white px-2 mr-1 mb-1">
                                {{ rejectedCount }} rejected
                            </span>
                        </p>
                    </div>
                </li>
                <activity-item v-for="activity in activities.slice(0).reverse()"
                               v-bind:key="activity.eventId"
                               v-bind:activity="activity">
                </activity-item>
            </ul>

            <!-- <ul class="list-group shadow-sm rounded-xl mb-3">
                <li class="position-sticky list-group-item d-flex justify-content-between bg-light pl-3 pr-1 py-1">
                    <div class="font-weight-bolder">
                        Income and Expenditure
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                    <p class="font-weight-bolder mb-0">Income</p>
                    <div class="d-flex align-items-center">
                        <h1 class="h5 text-center mb-0 mr-3">
                            <small>HK$</small><strong>700.00</strong>
                        </h1>
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center pl-3 p-2">
                    <p class="font-weight-bolder mb-0">Expenditure</p>
                    <div class="d-flex align-items-center">
                        <h1 class="h5 text-center mb-0 mr-3">
                            (<small>HK$</small><strong>300.00</strong>)
                        </h1>
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center pl-3 pt-2 pr-2 pb-1">
                    <p class="font-weight-bolder text-success mb-0">Profit</p>
                    <div class="d-flex align-items-center">
                        <h1 class="h5 text-center mb-0 mr-3"
                            style="border-bottom: double 4px rgba(0, 0, 0, 1)">
                            <small>HK$</small><strong>400.00</strong>
                        </h1>
                    </div>
                </li>
            </ul> -->
        </div>
    </side-panel>
</template>

<script>
import format from "date-fns/format";
import { BFormInput, } from "bootstrap-vue";
import ModalAdvancedActivitySearch from "@/components/Activities/ActivitiesPlaceholder/ModalAdvancedActivitySearch";
export default {
    name: "EventsPlaceholder",
    components: {
        ModalAdvancedActivitySearch,
        BFormInput,
        SidePanel: () => import("../SidePanel"),
        ActivityItem: () => import("./ActivitiesPlaceholder/ActivityItem"),
    },
    props: {
        start: {
            type: Date,
        },
        end: {
            type: Date,
        },
        activities: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isModalSearchOpen: false,
        };
    },
    computed: {
        startFormatted () {
            return format(new Date(this.start), "dd/MM/yyyy");
        },
        endFormatted () {
            return format(new Date(this.end), "dd/MM/yyyy");
        },
        pendingCount () {
            return this.activities.filter(({ status }) => status === "pending").length;
        },
        rejectedCount () {
            return this.activities.filter(({ status }) => status === "rejected").length;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 10;
}
</style>
